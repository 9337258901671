
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import { bus } from "@/bus";

export default defineComponent({
  name: "Intro",
  setup() {
    const currentWord = ref("");
    const currentClass = ref("");
    const words = ref(["web", "mobile", "desktop", "server"]);
    const intro = ref<HTMLDivElement | null>(null);
    const starsContainer = ref<HTMLDivElement | null>(null);

    function write(nextIndex: number, delay: number, callback: () => void) {
      const wordLetters = words.value[nextIndex].split("");
      const assembleWord: string[] = [];
      const interval = setInterval(() => {
        if (wordLetters.length > 0) {
          assembleWord.push(wordLetters[0]);
          wordLetters.shift();
          currentWord.value = assembleWord.join("");
        } else {
          clearInterval(interval);
          callback();
        }
      }, delay);
    }

    function erase(delay: number, callback: () => void) {
      const wordLetters = currentWord.value.split("");
      const interval = setInterval(() => {
        if (wordLetters.length > 0) {
          wordLetters.pop();
          currentWord.value = wordLetters.join("");
        } else {
          clearInterval(interval);
          callback();
        }
      }, delay);
    }

    function wordsInterval(delay = 1500) {
      const currentIndex = words.value.indexOf(currentWord.value);
      const nextIndex =
        currentIndex + 1 > words.value.length - 1 ? 0 : currentIndex + 1;

      setTimeout(() => {
        erase(100, () => {
          currentClass.value = words.value[nextIndex];
          write(nextIndex, 100, () => {
            wordsInterval();
          });
        });
      }, delay);
    }

    onMounted(() => {
      currentWord.value = words.value[0];
      currentClass.value = words.value[0];
      wordsInterval();

      bus.on("windowResize", () => {
        // console.log(window.outerWidth);
        // console.log(window.outerHeight);
        if (intro.value && starsContainer.value) {
          intro.value.style.width = starsContainer.value.style.width = "100%";
          intro.value.style.height = starsContainer.value.style.height =
            "100vh";
        }
      });
    });

    onBeforeUnmount(() => {
      bus.off("windowResize", () => null);
    });

    return { currentWord, currentClass, words, intro, starsContainer };
  },
});
